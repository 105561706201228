import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import animationData from './loader.json';

const LoaderStyled = styled.div`

`;

function Loader({
  id,
  size,
}) {
  const units = size === 'small' ? 16 : (size === 'medium' ? 50 : 64);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <LoaderStyled id={id} className='loader'>
      <Lottie
	      options={defaultOptions}
        height={units}
        width={units}
      />
    </LoaderStyled>
  )
};

Loader.defaultProps = {
  size: 'small',
};

export default Loader;
