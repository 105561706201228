import styled from 'styled-components'

export const ErrorPage = styled.div`
  padding:40px 0;
  background:#fff;
  text-align: center;
`

export const ErrorPageImage = styled.img`
  height: 400px;
  max-width: 100%;
  margin: 0 auto;
`

export const ErrorPageTitle = styled.h1`
  font-size: 80px;
  margin: 0 0 -18px;
  z-index: 10;
`

export const ErrorPageSubTitle = styled.h2`
  font-size: 30px;
  margin-top: -20px;
  margin-bottom: 10px;
`

export const ErrorPageText = styled.p`
  margin: 0 0 10px;
`

export const DefaultLink = styled.div`
  margin-top: 32px;

  a {
    color: #1A1A1A;
    text-decoration: none;
  }
`;