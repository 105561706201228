import React from 'react';
import styled from 'styled-components';
import exclamation from 'assets/exclamation.svg';
import Button from 'components/Button';
import { body4 } from 'components/Type';

const Box = styled.div`
  ${body4}
  padding: 16px;
  border-radius: 4px;
  background: ${props => props.variantGrey ? '#F6F6F4' : '#FECDD1'};
  margin: ${props => props.inverseMargin ? '30px 0 0' : '0 0 30px'};
  color: ${props => props.variantGrey ? '#4E4D4C' : 'inherit'};
  display: flex;

  img {
    margin-right: 16px;
    margin-top: 4px;
  }

  a {
    ${body4}
  }
`;

const urls = {
  goToForgotPassword: '/recovery',
}

function ErrorBox({
  children, inverseMargin, action, variantGrey,
}) {
  return (
    <Box inverseMargin={inverseMargin} variantGrey={variantGrey}>
      <div>
        <img src={exclamation} alt="exclamation" />
      </div>
      {action ? (
        <span>
          {children.split(action.text)[0] ? children.split(action.text)[0] : null}
          <Button
            variant="tertiary"
            to={urls[action.type]}
            style={{ fontSize: '' }}
          >
            {action.text}
          </Button>
          {children.split(action.text)[1] ? children.split(action.text)[1] : null}
        </span>
      ) : (<span>{children}</span>)}
    </Box>
  );
};

export default ErrorBox;
