import React, { useState } from 'react';
import styled from 'styled-components';
import { body3, body4 } from 'components/Type';
import eyeIcon from 'assets/eye.svg';
import errorIcon from 'assets/error-icon.svg';

const PasswordInputWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;

  .error-icon {
    position: absolute;
    right: 46px;
    height: 56px;
    top: ${props => props.error ? 'calc(50% + 0px)' : 'calc(50% + 13px)'};
    transform: translateY(-50%);
  }

  .eye {
    cursor: pointer;
    position: absolute;
    right: 20px;
    height: 56px;
    width: 15px;
    top: ${props => props.error ? 'calc(50% + 0px)' : 'calc(50% + 13px)'};
    transform: translateY(-50%);
  }

  .field-error {
    ${body4}
    white-space: normal;
    word-wrap: break-word;
    width: 100%;
    max-width: 635px;
    position: relative;
    color: #CE2C30;
    margin-top: 2px;
    transition: border-color .3s ease-in-out, color .3s ease-in-out;
    display: block;
    overflow: hidden;
    opacity: ${props => props.error ? '1' : '0'};
    max-height: ${props => props.error ? '200px' : '0'};
  }
`

const PasswordInput = styled.input`
  ${body3}
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #D0D0D0;
  border-color: ${props => props.error ? '#CE2C30' : '#D0D0D0'};
  border-radius: 5px;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  color: #4E4D4C;

  &:focus {
    outline: none;
    border-color: ${props => props.error ? '#CE2C30' : '#1A1A1A'};
    box-shadow: 0px 0px 1px #999;
    color: #1A1A1A;
  }

  &:disabled {
    color: #B2B1B0
  }

  ::placeholder {
    color: #B3B1B0;
  }
`
const Label = styled.label`
  ${body4}
  display: flex;
  margin-bottom: 5px;

  ${props => !props.error ? `color: #807E7D;` : null}

  ${props => props.disabled ? `color: #B2B1B0;` : null}

  ${props => !!props.error ? `color: #CE2C30;` : null}
`

function InputPassword({
  id, name, label, errorMessage, disabled,
  onChange, value, onFocus, hideEye,
}) {
  const [passVisibility, setPassVisibility] = useState();

  return (
    <PasswordInputWrapper error={!!errorMessage}>
      <Label error={!!errorMessage} disabled={disabled}>{ label }</Label>
      <PasswordInput
        id={id}
        name={name}
        type={passVisibility ? 'input' : 'password'}
        error={!!errorMessage}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        disabled={disabled}
      />
      {!errorMessage ? null : <img className="error-icon" src={errorIcon} alt="Ver Senha" />}
      {hideEye ? null : <img className="eye" onClick={() => setPassVisibility(!passVisibility)} src={eyeIcon} alt="The eye" />}
      <div className="field-error">{errorMessage}</div>
    </PasswordInputWrapper>
  )
};

InputPassword.defaultProps = {
  label: 'Senha'
}

export default InputPassword;
