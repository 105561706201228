export const DATA_KEY = "@happybiz-data";

export const isAuthenticated = () => localStorage.getItem(DATA_KEY) !== null;

export const getData = () => JSON.parse(localStorage.getItem(DATA_KEY));
export const setData = data => {
  localStorage.setItem(DATA_KEY, JSON.stringify(data));
};
export const deleteData = () => {
  localStorage.removeItem(DATA_KEY);
};
