import React from 'react';
import { Link } from 'react-router-dom';
import error404 from 'assets/404.gif';

import {
  ErrorPage,
  ErrorPageImage,
  ErrorPageTitle,
  ErrorPageSubTitle,
  ErrorPageText,
  DefaultLink,
} from 'styles/ErrorPage';

function NotFound() {
  return (
    <ErrorPage>
      <ErrorPageTitle>404</ErrorPageTitle>
      <ErrorPageImage src={error404} />
      <ErrorPageSubTitle>Aparentemente você está perdido</ErrorPageSubTitle>
      <ErrorPageText>A página que você está procurando não existe ou não está disponível!</ErrorPageText>
      <DefaultLink>
        <Link to="/">Me leve de volta para a página inicial</Link>
      </DefaultLink>
    </ErrorPage>
  );
}

export default NotFound;
